import React from "react"

import SEO from "../components/seo"
import Header from "../components/layout/js/header"
import Overlay from "../components/layout/js/overlay"
import Articles from "../components/portes/js/portes"
import Contacter from "../components/layout/js/contacter"
import Footer from "../components/layout/js/footer"
import Copyrights from "../components/layout/js/copyrights"

import { Link } from "gatsby"

const CategoriesPortes = () => {
  const structuredDataDoor = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "Qu’est ce que le vitrail moderne ?",
        acceptedAnswer: {
          "@type": "Answer",
          text:
            "Dominique Legris a consacré 40 ans de sa vie à l’art du vitrail. Sa maîtrise de l’art est reconnu à l’échelle internationale. Il a notamment travaillé à la restauration d’ornementations vitrée sur de nombreux monuments.",
        },
      },
      {
        "@type": "Question",
        name: "Quelle est notre sélection de vitraux de porte d’entrées ?",
        acceptedAnswer: {
          "@type": "Answer",
          text:
            "Notre sélection de vitraux de porte d'entrée vous ravira par sa grande diversité. Nous avons créé pour vous plus de cent modèles qui s’adapteront à tous les styles de logements et de bureaux.",
        },
      },
      {
        "@type": "Question",
        name: "Comment respecter des traditions dans l’art du vitrail ?",
        acceptedAnswer: {
          "@type": "Answer",
          text:
            "Les traditions sont importantes pour Fany Glass. La recherche d’innovation ne nous empêche pas de garder un contact avec les traditions.",
        },
      },
      {
        "@type": "Question",
        name: "Pourquoi contactez notre équipe mobile et à votre écoute ?",
        acceptedAnswer: {
          "@type": "Answer",
          text:
            "Quel que soit votre projet ou votre localisation, Fany Glass se met à votre service. Nous intervenons partout en France ainsi qu’à l’étranger.",
        },
      },
    ],
  }
  return (
    <>
      <SEO
        title="Vitrail porte d’entrée"
        description="Atelier spécialisé dans les vitraux en double vitrage pour portes d’entrée. Découvrez nos collections classiques et ART DECO sur mesures."
        structuredData={structuredDataDoor}
      />
      <Header />
      <Overlay />
      <Articles />
      <div id="vitrail-selection"></div>
      <div className="w-75-ns center first ph3">
        <h3>Quelle est notre sélection de vitraux de porte d’entrées ? </h3>
        <p>
          Notre sélection de <strong>vitraux de porte d'entrée</strong> vous
          ravira par sa grande diversité. Nous avons créé pour vous plus de cent
          modèles qui s’adapteront à tous les styles de logements et de bureaux.
        </p>
        <p>
          Nos modèles sont conçus pour les portes d’entrée avec ou sans meneau,
          accommodant ainsi aux mieux vos besoins. La technologie de{" "}
          <strong>vitrail porte d'entrée moderne</strong> qu’a développé le
          maître verrier Dominique Legris nous permet de réaliser votre
          installation sur-mesure, et sur le type de verre de votre choix. Votre
          porte d'entrée pourra ainsi bénéficier d’une protection
          anti-effraction et d’une isolation thermique et phonique renforcée.
        </p>
        <p>
          Des <strong>collections de vitraux</strong> pour vos portes d’entrées
          adaptées à tous les goûts.
        </p>
        <p>
          Fany Glass a développé pour vous quatre collections de décors. Quel
          que soit le style de votre habitation, ou bâtiment d’entreprise, vous
          trouverez le vitrail qui vous correspondra le mieux.
        </p>
        <ul>
          <li>
            <strong>Collection Classique</strong> : Retrouvez tout le charme du
            vitrail porte pour d’entrée classique. Les motifs, sobres et
            élégants donneront du cachet à votre habitation. Un charme à
            l’ancienne qui sera parfait pour les habitations disposant déjà d’un
            décor classique sur leur porte d’entrée et souhaitant profiter du
            confort moderne du double ou triple vitrage. La collection
            classique, par sa sobriété, sera également parfaitement adaptée aux
            portes de bureaux.
          </li>
          <li>
            <strong>Collection Art Déco</strong> : Il y a un siècle, le{" "}
            <Link to="/#vitrail-art-deco">vitrail art deco</Link> connaissait
            son heure de gloire. Succédant au mouvement Art Nouveau, caractérisé
            par ses formes organiques tout en rondeur, le mouvement Art Deco
            tranche par ses formes plus géométriques au style industriel.
            Retrouvez ce style si particulier et toujours autant envoûtant dans
            notre collection directement inspirée des Années Folles. Le vitrail
            art deco apportera une touche raffinée à votre porte d entrée
            vitrée.
          </li>
          <li>
            <strong>Collection Moderne</strong> : Les années 50 et 60 ont vu
            apparaître l’art moderne, si particulier et ancré dans son époque.
            Les formes géométriques se mêlent aux courbes dans notre collection
            inspirée des plus grandes heures de l’école Bauhaus. Habillez vos
            vitres de portes d’entrées de motifs sobres et hautement graphiques
            typiques du mouvement artistique.
          </li>
          <li>
            <strong>Collection Intemporelle</strong> : La gamme intemporelle est
            spécialement conçue pour les amateurs de travail détaillé, au charme
            ancien. Au plombage élégant se rajoutent des ornementations
            colorées. La collection intemporelle est une ode à la nature. Tout
            l’art de Dominique Legris et de son équipe se retrouve dans cette
            collection au style unique digne d’un conte de fées.
          </li>
        </ul>
        <h3>Comment respecter des traditions dans l’art du vitrail ?</h3>
        <p>
          Les <strong>traditions</strong> sont importantes pour{" "}
          <strong>Fany Glass</strong>. La recherche d’innovation ne nous empêche
          pas de garder un contact avec les traditions. Ainsi, que ce soit sur
          des monuments ou chez les particuliers, nous intervenons pour la
          réparation et la réalisation de vitrail traditionnel. Notre équipe
          expérimentée se tient prête à intervenir dans le respect des
          traditions verrières sur la réalisation personnalisée de vos projets.
        </p>
        <h3>Pourquoi contactez notre équipe mobile et à votre écoute ?</h3>
        <p>
          Quel que soit votre projet ou votre localisation, Fany Glass se met à
          votre service. Nous intervenons partout en France ainsi qu’à
          l’étranger. Après avoir pris contact, notre équipe s’appliquera à
          prendre en compte vos besoins pour vous proposer une solution adaptée.
          Tous nos projets sont personnalisés et validés par nos clients avant la mise en travaux.
        </p>
      </div>
      <Contacter />
      <Footer />
      <Copyrights />
    </>
  )
}

export default CategoriesPortes
