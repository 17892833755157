import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styles from "../css/portes.module.css"
import Img from "gatsby-image"
import { document } from "browser-monads"
import { FormattedMessage } from "gatsby-plugin-intl"

const Portes = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "portes.json" }) {
        childDataJson {
          portes {
            collection
            montant
            nom
            image {
              childImageSharp {
                fixed(width: 250) {
                  ...GatsbyImageSharpFixed
                }
                id
              }
            }
          }
        }
      }
    }
  `)

  const [isAvecChecked, setAvecCheckbox] = useState(true)
  const [isSansChecked, setSansCheckbox] = useState(true)

  let tabAvec = Array.from(document.getElementsByClassName("avec"))
  let tabSans = Array.from(document.getElementsByClassName("sans"))

  const handleChangeAvec = event => {
    if (isAvecChecked === true) {
      setAvecCheckbox(false)
      tabAvec = Array.from(document.getElementsByClassName("avec"))
      tabAvec.forEach(porte => {
        porte.style.display = "none"
      })
    } else {
      setAvecCheckbox(true)
      tabAvec.forEach(porte => {
        porte.style.display = "block"
      })
    }
  }
  const handleChangeSans = event => {
    if (isSansChecked === true) {
      setSansCheckbox(false)
      tabSans = Array.from(document.getElementsByClassName("sans"))
      tabSans.forEach(meneau => {
        meneau.style.display = "none"
      })
    } else {
      setSansCheckbox(true)
      tabSans.forEach(meneau => {
        meneau.style.display = "block"
      })
    }
  }

  return (
    <section id="portes" className="first w-75-ns center">
      <div id="vitrail-porte-entree"></div>
      <h1 className={styles.titre}>
        <FormattedMessage id="doors.title" />
      </h1>
      <div className="">
        <p>
          Que vous soyez un professionnel ou un particulier, Fany Glass vous
          accompagne dans tous vos projets de{" "}
          <strong>vitrail de porte d’entrée</strong>. Dominique Legris vous
          invite à en savoir plus sur son travail, et à découvrir la grande
          variété de vitraux de portes d'entrée que notre société propose.
        </p>
        <h2>Qu’est ce que le vitrail moderne ?</h2>
        <p>
          Dominique Legris a consacré 40 ans de sa vie à l’art du vitrail. Sa
          maîtrise de l’art est reconnu à l’échelle internationale. Il a
          notamment travaillé à la{" "}
          <strong>restauration d’ornementations</strong> vitrée sur de nombreux
          monuments. Le fondateur de Fany Glass a été récompensé Meilleur
          Ouvrier de France pour son travail en vitraux d’art en 2015, validant
          le travail de toute une vie. À travers notre{" "}
          <strong>artisanat</strong>, le maître verrier et son équipe continuent
          ce travail, améliorant sans cesse les produits et les techniques à la
          recherche de la perfection.
        </p>
        <p>
          Fruit de plusieurs{" "}
          <a href="#vitrail-selection">
            décennies de recherches et d’améliorations
          </a>
          , Fany Glass vous propose ces décors. Contrairement au vitrail
          traditionnel, le plombage n’est qu’esthétique. Le motif en plomb peut
          ainsi être posé sur des plaques de verre en double vitrage répondant
          aux normes de sécurité et d’isolation thermique et phonique. Vous
          pourrez ainsi allier confort moderne et art du vitrail. Fany Glass :
          pour portes d'entrée, avec ou sans meneau.
        </p>
      </div>
      <div className={(styles.choix += " flex justify-center mt5")}>
        <label className="mh2" htmlFor="avecmontant">
          <input
            className="mr2"
            type="checkbox"
            name="avec"
            id="avecmontant"
            checked={isAvecChecked}
            onChange={handleChangeAvec}
          />
          <FormattedMessage id="doors.check1" />
        </label>
        <label className="mh2" htmlFor="sansmontant">
          <input
            className="mr2"
            type="checkbox"
            name="sans"
            id="sansmontant"
            checked={isSansChecked}
            onChange={handleChangeSans}
          />
          <FormattedMessage id="doors.check2" />
        </label>
      </div>

      <div id="articles" className="flex flex-wrap justify-around mb6">
        {data.file.childDataJson.portes.map(porte => (
          <div
            key={porte.image.childImageSharp.id}
            className={(porte.montant += " w-100 w-50-ns w-33-l mv3")}
          >
            <Img
              fixed={porte.image.childImageSharp.fixed}
              style={{ display: "block", margin: "0 auto" }}
              alt={porte.nom}
            />

            <p className={styles.contenu} style={{ margin: "10px auto" }}>
              {porte.nom} <br />
              {porte.collection}
            </p>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Portes
